export default {
    state: {
        menu: [
          { product: true, title: 'UCP Home', bgcolor: 'primary' },
          {
            title: 'Dashboard', icon: 'mdi mdi-view-dashboard-outline', href: '/home/dashboard',
          },
          {
            title: 'Profile Info', icon: 'mdi mdi-card-account-details-outline', href: '/home/profile',
          },
          {
            title: 'Locations', icon: 'mdi-folder-marker-outline', href: '/home/locations',
          },
        ],
    },
    mutations: {
    },
    actions: {
    },
    getters: {
        
    },
}